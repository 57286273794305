import React from 'react'
import {useSkilQuery} from '../../Utilities/QueryClient'
import GoalCard from './GoalCard'
import LoadingComponent from '../../Components/LoadingComponent'

const NewParticipantsGoal25 = () => {
    const {data: newParticipants} = useSkilQuery<'getNewParticipantsPerYear'>(`/api/users/newParticipantsPerYear`, {year: 2025})

    if (!newParticipants) {
        return <LoadingComponent />
    }

    return (
        <GoalCard
            title='Nye deltakere'
            subheader='(Nye brukere som er påmeldt minst ett kurs)'
            // @ts-expect-error
            description={`${newParticipants?.count} nye deltakere hittil i 2025.`}
            percentage={null}
        />
    )
}

export default NewParticipantsGoal25
