import * as React from 'react'
import {Box} from '@mui/material'
import QualitySeminarGoal25 from './QualitySeminarGoal25'
import NewParticipantsGoal25 from './NewParticipantsGoal25'
import NewUserCoursesGoal25 from './NewUserCoursesGoal25'
import QualitySeminarsGoal25 from './QualitySeminarsGoal25'
import NefleStats from './NefleStats'
import Type0CourseCompletions from './Type0CourseCompletions'
import Grid from '@mui/material/Grid'
import Type1CourseCompletions from './Type1CourseCompletions'
import Type2CourseCompletions from './Type2CourseCompletions'

export const Goals25 = () => {
    return (
        <>
            <Grid container spacing={1} mb={3} mt={5}>
                <Grid display={'flex'} width={'100%'} maxWidth={'100%'} xs={12} sm={4} md={3} item>
                    <QualitySeminarGoal25 />
                </Grid>
                <Grid display={'flex'} width={'100%'} maxWidth={'100%'} xs={12} sm={4} md={3} item>
                    <NewParticipantsGoal25 />
                </Grid>
                <Grid display={'flex'} width={'100%'} maxWidth={'100%'} xs={12} sm={4} md={3} item>
                    <NewUserCoursesGoal25 />
                </Grid>
                <Grid display={'flex'} width={'100%'} maxWidth={'100%'} xs={12} sm={4} md={3} item>
                    <QualitySeminarsGoal25 />
                </Grid>
                <Grid display={'flex'} width={'100%'} maxWidth={'100%'} xs={12} sm={4} md={3} item>
                    <NefleStats />
                </Grid>
                <Grid display={'flex'} width={'100%'} maxWidth={'100%'} xs={12} sm={4} md={3} item>
                    <Type0CourseCompletions />
                </Grid>
                <Grid display={'flex'} width={'100%'} maxWidth={'100%'} xs={12} sm={4} md={3} item>
                    <Type1CourseCompletions />
                </Grid>
                <Grid display={'flex'} width={'100%'} maxWidth={'100%'} xs={12} sm={4} md={3} item>
                    <Type2CourseCompletions />
                </Grid>
            </Grid>
        </>
    )
}
